module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"11","disableCookies":true,"matomoUrl":"https://s.fixmycity.de","siteUrl":"https://radschnellweg-frm7.de"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Radschnellweg 7 | Der neue Radschnellweg von Frankfurt am Main nach Hanau","short_name":"FRM7","start_url":"/","background_color":"white","display":"minimal-ui","legacy":false,"theme_color":"#e6007e","icon":"src/images/frm7_version2.png","lang":"de-DE","theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3a2c737ed054af13060ead78a46b7166"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
